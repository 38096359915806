<template>
  <v-main>
    <v-snackbar v-model="alert.show" :color="alert.color" top>
      {{ alert.message }}
      <v-icon small dark @click="alert.show = false">close</v-icon>
    </v-snackbar>

    <v-container fill-height fluid class="pa-0">
      <v-layout align-center justify-center class="login-container">
        <v-flex xs11 sm9>
          <v-card>
            <v-card-text>
              <v-row align="center">
                <v-col
                  class="text-md-left text-center pb-0"
                  md="2"
                  offset-md="3"
                >
                  <v-img
                    :src="logoPath"
                    width="120"
                    class="d-md-block d-none"
                  />
                </v-col>
                <v-col class="d-md-block d-none">
                  <h1 class="mb-3">{{ $t("login.mainTitle") }}</h1>
                  <h2>{{ $t("login.subTitle") }}</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-md-none mb-3 pt-0 text-center">
                  <h2 class="mb-1">{{ $t("login.mainTitle") }}</h2>
                  <h4>{{ $t("login.subTitle") }}</h4>
                </v-col>
              </v-row>
              <v-form ref="loginForm">
                <v-row>
                  <v-col md="6" offset-md="3" class="py-0">
                    <v-text-field
                      :label="$t('login.username')"
                      v-model="username"
                      outlined
                      :rules="[required('Username')]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" offset-md="3" class="py-0">
                    <v-text-field
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      name="input-10-2"
                      :label="$t('login.password')"
                      v-model="password"
                      @click:append="showPassword = !showPassword"
                      outlined
                      :rules="[required('Password')]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-row>
                <v-col md="6" offset-md="3">
                  <v-card-actions class="justify-center pa-0">
                    <v-btn block color="primary" @click="handleLoginSubmit()">{{
                      $t("login.submitButton")
                    }}</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/store/actions/account.js";
import validations from "@/helpers/validations";

export default {
  name: "Login",
  data: () => ({
    ...validations,
    username: "",
    password: "",
    logoPath: require(`@/assets/images/logo.png`),
    showPassword: false
  }),
  computed: {
    ...mapState({
      alert: state => state.global.alert
    })
  },
  methods: {
    ...mapActions("account", [LOGIN, LOGOUT]),
    handleLoginSubmit() {
      if (this.username && this.password) {
        let identifier = this.username;
        let password = this.password;

        this.LOGIN({ identifier, password });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  background-image: url("/auth_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  // display: inline;
}
.login-card {
  width: 100%;
}
@media (min-width: 768px) {
  .login-container {
    margin: 0px;
  }
  .login-card {
    width: 80%;
    margin-left: 10%;
  }
}
</style>